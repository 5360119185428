<!--
 * @Author: LazyQ
 * @Date: 2020-04-13 13:21:26
 * @LastEditTime: 2020-04-21 10:24:16
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.left-20 {
  margin-left: 20px;
}
.left-10 {
  margin-left: 10px;
}
.top-10 {
  margin-top: 10px;
}
.top-20 {
  margin-top: 20px;
}
.width-200 {
  width: 200px;
}
</style>
<style>
.ant-table-tbody {
  /* font-size: 10px !important; */
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 1) !important;
}
.ant-table-row {
  height: 37px !important;
}
</style>

<template>
  <span>
    <a-button type="primary" @click="openFilter">选择筛选项</a-button>
    <a-modal
      title="筛选项"
      :maskClosable="false"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
    <template slot="footer">
      <a-button @click="handleClear">
        清空
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
        确认
      </a-button>
    </template>
    <div>
      <span>性别</span>
      <a-select v-model="sex" class="left-20 width-200" placeholder="请选择性别" slot="custom" style="margin-left: 45px">
        <a-select-option :key="1">男</a-select-option>
        <a-select-option :key="2">女</a-select-option>
      </a-select>
    </div>
    <div class="top-20">
      <span>生日区间</span>
      <a-range-picker v-model="startDate" class="left-20"  @change="handlePanelChange">
        <template slot="dateRender" slot-scope="current">
          <div class="ant-calendar-date" >
            {{ current.date() }}
          </div>
        </template>
      </a-range-picker>
    </div>
    <div class="top-20">
      <span>居住地</span>
      <a-tree-select
        class="top-10" 
        v-model="cityData"
        style="width: 100%"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :tree-data="provinceData"
        tree-checkable
        tree-data-simple-mode
        placeholder="请选择居住地"
        :load-data="onLoadData"
      />
    </div>
    <div class="top-20">
      <span>会员ID</span>
      <a-input class="top-10" type="textarea" v-model="memberId" placeholder="请输入用户ID，使用逗号分隔" style="height:100px"/>
    </div>
    </a-modal>  
  </span>
</template>

<script>
import { getArea } from "@/api/base.js";
import Vue from 'vue';

export default {
  props: {
    customer_name: {
      type: String,
      default: undefined
    },
    customer_id: undefined,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      startDate: undefined,
      memberId: undefined,
      sex: undefined,
      provinceData: [],
      cityData: undefined,
    };
  },
  created() {
  },
  methods: {
    /// 初始化数据
    initData() {
      this.fetchArea();
    },
    onLoadData(treeNode) {
      const that = this;
      return new Promise(resolve => {
        const id = treeNode.dataRef.id;
        getArea({
          parent_id: id,
          page_size: 100
        })
        .then(area => {
          let listData = area.area_list.map((item, index) => {
            var newItem = {};
            newItem["pId"] = item.parent_id;
            newItem["id"] = item.id;
            newItem["value"] = item.id + '';
            newItem["title"] = item.short_name;
            newItem["isLeaf"] = true;
            return newItem;
          });
          that.provinceData = that.provinceData.concat(listData);
          resolve();
        })
      });
    },
    /// 获取区域
    async fetchArea() {
      try {
        let area = await getArea({
          page_size: 100
        });
        let listData = area.area_list.map((item, index) => {
          var newItem = {};
          newItem["pId"] = item.parent_id;
          newItem["id"] = item.id;
          newItem["value"] = item.id + '';
          newItem["title"] = item.area_name;
          return newItem;
        });
        this.provinceData = listData;
      } catch (error) {
        console.log(error);
      }
    },
    handlePanelChange(value, mode) {
      this.startDate = mode;
    },
    /// 选择客户
    openFilter() {
      this.visible = true;
      if (this.provinceData.length <= 0) {
        this.initData(); 
      }
    },
    handleOk(e) {
      var dic = {"gender": this.sex, "birthday": this.startDate, "member_id": this.memberId};
      if (this.cityData != undefined) {
        console.log(this.cityData);
        dic["city"] = this.cityData.join(",");
      }
      this.$emit('filterHandler', dic);
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleClear(e) {
      this.sex = undefined;
      this.startDate = undefined;
      this.memberId = undefined;
      this.cityData = undefined;
      this.$emit('filterHandler', {});
      this.visible = false;
    },
    
  }
};

</script>