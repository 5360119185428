/*
 * @Author: JiaQi
 * @Date: 2021-03-04 21:36:32
 * @LastEditors: JiaQi
 * @LastEditTime: 2021-03-06 11:45:42
 * @Description: 通用模块
 */
import https from '../utils/https'

// 获取短信Token接口
export function getSmsToken(params) {
  return https.fetchGet('/api/smstoken', params)
}

// 获取行政区划
export function getArea(params) {
  return https.fetchGet('/api/area', params)
}

// 获取邀请排行榜
export function fetchInviterankinglist(params) {
  return https.fetchGet('/api/inviteranking/list', params)
}

// 获取项目属性选项列表接扣
/// 获取学历
export function fetchEducation() {
  return https.fetchGet('/api/member/propertyitemlist', { 
    category: "education"
  })
}

/// 获取工作状态
export function fetchWorkStatus() {
  return https.fetchGet('/api/member/propertyitemlist', { 
    category: "work_status"
  })
}

/// 获取职位
export function fetchProfession() {
  return https.fetchGet('/api/member/propertyitemlist', { 
    category: "profession"
  })
}

/// 获取工资
export function fetchSalary() {
  return https.fetchGet('/api/member/propertyitemlist', { 
    category: "salary"
  })
}

/// 获取家庭收入
export function fetchFamilyIncome() {
  return https.fetchGet('/api/member/propertyitemlist', { 
    category: "family_income"
  })
}

/// 获取私家车
export function fetchAutomobileBrand() {
  return https.fetchGet('/api/member/propertyitemlist', { 
    category: "automobile_brand"
  })
}

/// 获取小孩
export function fetchchildStatus() {
  return https.fetchGet('/api/member/propertyitemlist', {  
    category: "child_status"
  })
}

/// 获取婚姻状态
export function fetchmarriage() {
  return https.fetchGet('/api/member/propertyitemlist', { 
    category: "marriage"
  })
}